

import Caption from '../../layout/Child/Caption';
import ChildImage from '../../layout/Child/ChildImage';
import Icon from '../../layout/Child/Icon';

const CloudflarestreamNotSupport = ({ attributes, type, mixAllData, clientId, caption, content, EVideo, lightboxType, img, thumbnail, captionPosition, galleryIcon, ButtonContent }) => {
    const { content: showContent } = attributes;

    return <a data-fancybox={`${mixAllData ? `allDataGallery-${clientId}` : `video-gallery-${clientId}`}`} data-caption={caption} className="lbbmodalbtn" href={`${content === '' ? EVideo : content}`} data-type={`${content === '' ? '' : 'html5video'}`}>
        {"gallery" === lightboxType ? <div className={`contentArea ${showContent?.overlyColor && "overlyColor"}`}>
            <ChildImage img={img} thumbnail={thumbnail} />
            <Caption showContent={showContent} caption={caption} captionPosition={captionPosition} />
            <Icon type={type} galleryIcon={galleryIcon} />
        </div>
            : <button className='lbbBtnDesign'><ButtonContent /></button>
        }
    </a>
}
export default CloudflarestreamNotSupport;
