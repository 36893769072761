

import Caption from '../layout/Child/Caption';
import ChildImage from '../layout/Child/ChildImage';

const Image = ({ attributes, type, mixAllData, clientId, caption, content, EImage, lightboxType, img, captionPosition, ButtonContent }) => {
    
    const { content: showContent } = attributes;
    return 'image' === type && <a data-fancybox={`${mixAllData ? `allDataGallery-${clientId}` : `gallery-${clientId}`}`} data-caption={caption} className="lbbmodalbtn fancybox" href={`${content === '' ? EImage : content}`} >
        {"gallery" === lightboxType ? <div className={`contentArea ${showContent?.overlyColor && "overlyColor"}`}>
            <ChildImage img={img} thumbnail={content} />
            <Caption showContent={showContent} caption={caption} captionPosition={captionPosition} />
        </div>
            : <button className='lbbBtnDesign'><ButtonContent /></button>
        }
    </a>
}
export default Image;