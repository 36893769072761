import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Image from '../MediaType/Image';
import Audio from '../MediaType/Audio';
import Video from '../MediaType/Video';
import Content from '../MediaType/Content';
import Pdf from '../MediaType/Pdf';
import Iframe from '../MediaType/Iframe';


const MasonryLayout = ({ attributes, filteredItems, mixAllData, clientId, EImage, ButtonContent, EAudio, EVideo, galleryIcon, setOpen, setActiveIndex, isOpen, custom, activeIndex, ContentArea, Modal }) => {
    const { lightboxType, columnGap, rowGap, img, caption: captionPosition, columns } = attributes;
    const { desktop, tablet, mobile } = columns;
    return <ResponsiveMasonry columnsCountBreakPoints={{ 0: mobile, 576: tablet, 768: desktop }}>
        <Masonry gutter={`${rowGap} ${columnGap}`}>
            {filteredItems?.map((item, index) => {
                const { type,thumbnail, thumbCaption, content, contentCaption, caption: customCaption, importCaption } = item || {};
                const caption = importCaption ? (type == 'image' ? contentCaption : thumbCaption) : customCaption;
                const commonProps = { type, clientId, caption, lightboxType, img, captionPosition, ButtonContent }

                const imageTypeNotSupport = { 
                    thumbnail, 
                    galleryIcon
                }

                return <div key={index} className=" lbbContent_area">

                    <Image {...commonProps} mixAllData={mixAllData} content={content} EImage={EImage} attributes={attributes} />

                    <Audio {...commonProps}{...imageTypeNotSupport} mixAllData={mixAllData} content={content} EAudio={EAudio} attributes={attributes} />

                    <Video {...commonProps}{...imageTypeNotSupport} content={content} mixAllData={mixAllData} EVideo={EVideo} index={index} attributes={attributes} />

                    <Content {...commonProps}{...imageTypeNotSupport} setOpen={setOpen} setActiveIndex={setActiveIndex} index={index} isOpen={isOpen} activeIndex={activeIndex} Modal={Modal} ContentArea={ContentArea} item={item} custom={custom} />

                    {/* new code start  */}
                    <Pdf {...commonProps}{...imageTypeNotSupport} mixAllData={mixAllData} index={index} attributes={attributes} content={content} EAudio={EAudio} />

                    <Iframe {...commonProps}{...imageTypeNotSupport} mixAllData={mixAllData} content={content} EAudio={EAudio} attributes={attributes} />
                </div>
            })}
        </Masonry>
    </ResponsiveMasonry>
}
export default MasonryLayout;