

import CloudflareSupport from './Cloudflarestream/CloudflareSupport';
import CloudflarestreamNotSupport from './Cloudflarestream/CloudflarestreamNotSupport';


const Video = ({ attributes, type, content, mixAllData, clientId, caption, EVideo, lightboxType, img, thumbnail, captionPosition, galleryIcon, ButtonContent, index }) => {
    return <>
        {
            ('video' === type && content.split('.')[1] !== 'cloudflarestream') ? <CloudflarestreamNotSupport type={type} mixAllData={mixAllData} clientId={clientId} caption={caption} content={content} EVideo={EVideo} lightboxType={lightboxType} img={img} thumbnail={thumbnail} captionPosition={captionPosition} galleryIcon={galleryIcon} ButtonContent={ButtonContent} attributes={attributes} /> :
                ('video' === type && content.split('.')[1] === 'cloudflarestream') && <CloudflareSupport type={type} clientId={clientId} index={index} content={content} mixAllData={mixAllData} caption={caption} lightboxType={lightboxType} img={img} thumbnail={thumbnail} captionPosition={captionPosition} galleryIcon={galleryIcon} ButtonContent={ButtonContent} attributes={attributes} />
        }
    </>
}
export default Video;