import Audio from '../MediaType/Audio';
import Content from '../MediaType/Content';
import Image from '../MediaType/Image';
import Pdf from '../MediaType/Pdf';
import Video from '../MediaType/Video';
import Iframe from '../MediaType/Iframe';

const Default = ({ attributes, filteredItems, mixAllData, clientId, EImage, ButtonContent, EAudio, EVideo, galleryIcon, setOpen, setActiveIndex, isOpen, custom, activeIndex, ContentArea, Modal }) => {
    const { lightboxType, img, caption: captionPosition } = attributes;

    return filteredItems?.map((item, index) => {
        const { type, thumbnail, thumbCaption, content, contentCaption, caption: customCaption, importCaption } = item || {};

        const caption = importCaption ? (type == 'image' ? contentCaption : thumbCaption) : customCaption;
        const commonProps = { type, clientId, caption, importCaption, lightboxType, img, captionPosition, ButtonContent };

        const imageTypeNotSupport = { 
            thumbnail, 
            galleryIcon
        }

        return <div key={index} className="flex flex-wrap gap-5 justify-center max-w-5xl mx-auto px-6 lbbContent_area">

            <Image {...commonProps} mixAllData={mixAllData} content={content} EImage={EImage} attributes={attributes} />

            <Audio {...commonProps}{...imageTypeNotSupport} mixAllData={mixAllData} content={content} EAudio={EAudio} attributes={attributes} />

            <Video {...commonProps}{...imageTypeNotSupport} mixAllData={mixAllData} content={content} EVideo={EVideo} index={index} attributes={attributes} />

            <Content {...commonProps}{...imageTypeNotSupport} setOpen={setOpen} setActiveIndex={setActiveIndex} index={index} isOpen={isOpen} activeIndex={activeIndex} Modal={Modal} ContentArea={ContentArea} item={item} custom={custom} />

            {/* new code start  */}
            <Pdf {...commonProps}{...imageTypeNotSupport} mixAllData={mixAllData} index={index} attributes={attributes} content={content} EAudio={EAudio} />

            <Iframe {...commonProps}{...imageTypeNotSupport} mixAllData={mixAllData} content={content} EAudio={EAudio} attributes={attributes} />
        </div>
    })
}
export default Default;