

export const ratioCheck = (val = "9:16") => {
    const [width, height] = val.split(':');
    const result = (parseInt(height) / parseInt(width)) * 100;
    return result;
}

export const getNumber = (val) => {
    const colGap = val;
    const columnGap = colGap.match(/\d/g);
    const gutterX = parseInt(columnGap.join(""));
    return gutterX;
}

export const getBoxValue = object => Object.values(object).join(" ");


export function loadFrameIfNotLoaded(iframe) {

    if (iframe && iframe.contentDocument !== null) {
      const source = iframe.src;
      iframe.src = source;
      setTimeout(() => {
        loadFrameIfNotLoaded(iframe);
      }, 1200);
    }  
}