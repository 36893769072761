import Caption from '../layout/Child/Caption';
import ChildImage from '../layout/Child/ChildImage';
import Icon from '../layout/Child/Icon';


const Iframe = ({ attributes, type, mixAllData, clientId, content, EAudio, lightboxType, img, thumbnail, caption, captionPosition, galleryIcon, ButtonContent }) => {
    const { content: showContent } = attributes;
    return 'iframe' === type && <a data-fancybox={`${mixAllData ? `allDataGallery-${clientId}` : `iframe-gallery-${clientId}`}`} className="lbbmodalbtn" data-src={`${content === '' ? EAudio : content}`} data-type={`${content === '' ? '' : 'iframe'}`} data-options='{ "iframe" : { "preload" : false, "css" : { "width" : "600px" } } }' href="javascript:;">
        {"gallery" === lightboxType ? <div className={`contentArea ${showContent?.overlyColor && "overlyColor"}`}>
            <ChildImage img={img} thumbnail={thumbnail} />
            <Caption showContent={showContent} caption={caption} captionPosition={captionPosition} />
            <Icon type={type} galleryIcon={galleryIcon} />
        </div>
            : <button className='lbbBtnDesign'><ButtonContent /></button>
        }
    </a>

}
export default Iframe;