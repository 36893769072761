import { useEffect, useState } from 'react';

import Audio from '../MediaType/Audio';
import Content from '../MediaType/Content';
import Image from '../MediaType/Image';
import Pdf from '../MediaType/Pdf';
import Video from '../MediaType/Video';
import Iframe from '../MediaType/Iframe';
import { placeholderImg } from '../../utils/links';

const Slider = ({ attributes, filteredItems, mixAllData, clientId, EImage, ButtonContent, EAudio, EVideo, galleryIcon, setOpen, setActiveIndex, isOpen, custom, activeIndex, ContentArea, Modal }) => {
    const { lightboxType, img, caption: captionPosition, layout, slider } = attributes;

    const [carousel, setCarousel] = useState(null);
    const [carouselItems, setCaroulelItems] = useState(filteredItems);

    useEffect(() => {
        carousel?.destroy();
        setCaroulelItems(filteredItems);

    }, [filteredItems, slider]);

    useEffect(() => {
        const cr = new Carousel(document.getElementById("lbbCarousel"), {
            Dots: false,
            Thumbs: {
                type: slider?.thumbs,
            },
        }, { Thumbs });

        setCarousel(cr);
    }, [carouselItems, layout, slider]);

    return <div className='f-carousel' id='lbbCarousel'>
        {carouselItems?.map((item, index) => {
            const { type, thumbnail, thumbCaption, content, contentCaption, caption: customCaption, importCaption } = item || {};

            const caption = importCaption ? (type == 'image' ? contentCaption : thumbCaption) : customCaption;
            const commonProps = {type,clientId,caption,lightboxType,img,captionPosition,ButtonContent}

            const imageTypeNotSupport = { 
                thumbnail, 
                galleryIcon
            }

            return <div key={index} className="f-carousel__slide flex flex-wrap gap-5 justify-center max-w-5xl mx-auto px-6 lbbContent_area" data-thumb-src={type === 'image' &&  content || thumbnail || placeholderImg}>

                <Image {...commonProps} mixAllData={mixAllData} content={content} EImage={EImage} attributes={attributes} />

                <Audio {...commonProps}{...imageTypeNotSupport} mixAllData={mixAllData} content={content} EAudio={EAudio} attributes={attributes} />

                <Video {...commonProps}{...imageTypeNotSupport} content={content} mixAllData={mixAllData} EVideo={EVideo} index={index} attributes={attributes} />

                <Content {...commonProps}{...imageTypeNotSupport} setOpen={setOpen} setActiveIndex={setActiveIndex} index={index} isOpen={isOpen} activeIndex={activeIndex} Modal={Modal} ContentArea={ContentArea} item={item} custom={custom} />

                {/* new code start  */}
                <Pdf {...commonProps}{...imageTypeNotSupport} mixAllData={mixAllData} index={index} attributes={attributes} content={content} EAudio={EAudio} />

                <Iframe {...commonProps}{...imageTypeNotSupport} mixAllData={mixAllData} content={content} EAudio={EAudio} attributes={attributes} />
            </div>
        })}
    </div>

}
export default Slider;