

import { placeholderImg } from '../../../utils/links';
import Caption from '../../layout/Child/Caption';
import ChildImage from '../../layout/Child/ChildImage';
import Icon from '../../layout/Child/Icon';


const CloudflareSupport = ({ type, attributes, clientId, index, content, mixAllData, caption, lightboxType, img, thumbnail, captionPosition, galleryIcon, ButtonContent }) => {
    const { content: showContent } = attributes;
    return <>
        <div id={`dialog-content-${clientId}${index}`} className='iframeHidden'>
            <div className="feedPopUpArea">
                <div className="feedPopUp">
                    <iframe src={content} className='iframe' />
                </div>
            </div>
        </div>

        <a data-fancybox={`${mixAllData ? `allDataGallery-${clientId}` : `video-gallery-${clientId}`}`} data-src={`#dialog-content-${clientId}${index}`} data-caption={caption} className="feedItem">
            {"gallery" === lightboxType ? <div className={`contentArea ${showContent?.overlyColor && "overlyColor"}`}>
                <div className={`img ${img?.animation}`}>
                    <img className="rounded" src={thumbnail || placeholderImg} />
                </div>
                <ChildImage img={img} thumbnail={thumbnail} />
                <Caption showContent={showContent} caption={caption} captionPosition={captionPosition} />
                <Icon type={type} galleryIcon={galleryIcon} />
            </div>
                : <button className='lbbBtnDesign'><ButtonContent /></button>
            }
        </a>
    </>
}
export default CloudflareSupport;
