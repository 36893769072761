import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';
import { loadFrameIfNotLoaded } from './utils/functions';

export const lbbConfig = (cId, popupIconLeft, popupIconMiddle, popupIconRight, thumb, slideShow, options, controls) => {

    const leftSidePopOP = Object.keys(popupIconLeft).filter(key => popupIconLeft[key]);
    const middleSidePopOP = Object.keys(popupIconMiddle).filter(key => popupIconMiddle[key]);
    const rightSidePopOP = Object.keys(popupIconRight).filter(key => popupIconRight[key]);
    const controlsOpt = Object.keys(controls).filter(key => controls[key]);

    const twitter = {
        tpl: '<button class="f-button"><svg><path stroke="none" d="M0 0h24v24H0z"/><path d="M22 4.01c-1 .49-1.98.689-3 .99-1.121-1.265-2.783-1.335-4.38-.737S11.977 6.323 12 8v1c-3.245.083-6.135-1.395-8-4 0 0-4.182 7.433 4 11-1.872 1.247-3.739 2.088-6 2 3.308 1.803 6.913 2.423 10.034 1.517 3.58-1.04 6.522-3.723 7.651-7.742a13.84 13.84 0 0 0 .497-3.753c0-.249 1.51-2.772 1.818-4.013z"/></svg></button>',
        click: () => {
            window.open(
                "http://twitter.com/share?url=" +
                encodeURIComponent(window.location.href) +
                "&text=" +
                encodeURIComponent(document.title),
                "",
                "left=0,top=0,width=600,height=300,menubar=no,toolbar=no,resizable=yes,scrollbars=yes"
            );
        },
    }

    const facebook = {
        tpl: '<button class="f-button"><svg><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"/></svg></button>',
        click: () => {
            window.open(
                "https://www.facebook.com/sharer/sharer.php?u=" +
                encodeURIComponent(window.location.href) +
                "&t=" +
                encodeURIComponent(document.title),
                "",
                "left=0,top=0,width=600,height=300,menubar=no,toolbar=no,resizable=yes,scrollbars=yes"
            );
        }
    }

    Fancybox.bind(`[data-fancybox='gallery-${cId}']`, {
        wheel: options.wheel,
        autoFocus: false,
        backdropClick: "close",
        closeButton: "auto",
        commonCaption: false,
        contentClick: "toggleZoom", // "toggleZoom" | "toggleCover" | "toggleMax" | "zoomToFit" | "zoomToMax" | "iterateZoom" | false | "close" | "next" | "prev";
        contentDblClick: "toggleCover", // "toggleZoom" | "toggleCover" | "toggleMax" | "zoomToFit" | "zoomToMax" | "iterateZoom" | false | "close" | "next" | "prev";
        // defaultDisplay: "flex",
        Carousel: {
            transition: options?.transition
        },
        Toolbar: {
            items: {
                facebook,
                twitter
            },
            display: {
                left: leftSidePopOP,
                middle: middleSidePopOP,
                right: rightSidePopOP
            },
        },
        Thumbs: {
            type: thumb.type,
            showOnStart: thumb.showOnStart,
            minCount: 2,
        },

        Slideshow: {
            playOnStart: slideShow?.playOnStart,
            timeout: slideShow?.timeout,
        },
        on: {
            init: (fancybox,) => {
                setTimeout(() => {
                    if (fancybox.container) {
                        fancybox.container.classList.add("lbb_modal_area-" + cId);
                    }
                }, 100);
            },
        },
    });

    Fancybox.bind(`[data-fancybox='audio-gallery-${cId}']`, {
        on: {
            done: () => {
                const videoElement = document.querySelectorAll('.fancybox__html5video');
                const youtubeVideos = document.querySelectorAll('.has-youtube .fancybox__content');
                const vimeoVideos = document.querySelectorAll('.has-vimeo .fancybox__content');
                Plyr.setup(videoElement, {
                    controls: controlsOpt
                });
                const youtubePlayers = Plyr.setup(youtubeVideos);
                const vimeoPlayers = Plyr.setup(vimeoVideos);
                youtubePlayers?.forEach(player => player.elements.container.classList.add('fancybox__content'));
                vimeoPlayers?.forEach(player => player.elements.container.classList.add('fancybox__content'));
            },
            init: (fancybox) => {
                setTimeout(() => {
                    if (fancybox.container) {
                        fancybox.container.classList.add("lbb_modal_area-" + cId);
                    }
                }, 100);
            },
        },
        wheel: options.wheel,
        autoFocus: false,
        backdropClick: "close",
        closeButton: "auto",
        commonCaption: false,
        contentClick: "toggleZoom", // "toggleZoom" | "toggleCover" | "toggleMax" | "zoomToFit" | "zoomToMax" | "iterateZoom" | false | "close" | "next" | "prev";
        contentDblClick: "toggleCover", // "toggleZoom" | "toggleCover" | "toggleMax" | "zoomToFit" | "zoomToMax" | "iterateZoom" | false | "close" | "next" | "prev";
        // defaultDisplay: "flex",
        Carousel: {
            transition: options?.transition
        },
        Toolbar: {
            items: {
                facebook,
                twitter
            },
            display: {
                left: leftSidePopOP,
                middle: middleSidePopOP,
                right: rightSidePopOP,
            },


        },
        Thumbs: {
            type: thumb.type,
            showOnStart: thumb.showOnStart,
            minCount: 2,
        },

        Slideshow: {
            playOnStart: slideShow?.playOnStart,
            timeout: slideShow?.timeout,

        }
    });

    Fancybox.bind(`[data-fancybox='video-gallery-${cId}']`, {
        on: {
            done: () => {
                const videoElement = document.querySelectorAll('.fancybox__html5video');
                const youtubeVideos = document.querySelectorAll('.has-youtube .fancybox__content');
                const vimeoVideos = document.querySelectorAll('.has-vimeo .fancybox__content');
                Plyr.setup(videoElement, {
                    controls: controlsOpt,
                });
                const youtubePlayers = Plyr.setup(youtubeVideos);
                const vimeoPlayers = Plyr.setup(vimeoVideos);
                youtubePlayers?.forEach(player => player.elements.container.classList.add('fancybox__content'));
                vimeoPlayers?.forEach(player => player.elements.container.classList.add('fancybox__content'));
            },
            init: (fancybox) => {
                setTimeout(() => {
                    if (fancybox.container) {
                        fancybox.container.classList.add("lbb_modal_area-" + cId);
                    }
                }, 100);
            },
        },

        wheel: options.wheel,
        autoFocus: false,
        backdropClick: "close",
        closeButton: "auto",
        commonCaption: false,
        contentClick: "toggleZoom", // "toggleZoom" | "toggleCover" | "toggleMax" | "zoomToFit" | "zoomToMax" | "iterateZoom" | false | "close" | "next" | "prev";
        contentDblClick: "toggleCover", // "toggleZoom" | "toggleCover" | "toggleMax" | "zoomToFit" | "zoomToMax" | "iterateZoom" | false | "close" | "next" | "prev";
        defaultDisplay: "flex",
        Carousel: {
            transition: options?.transition
        },
        Toolbar: {
            items: {
                facebook,
                twitter
            },
            display: {
                left: leftSidePopOP,
                middle: middleSidePopOP,
                right: rightSidePopOP,
            }
        },
        Thumbs: {
            type: thumb.type,
            showOnStart: thumb.showOnStart,
            minCount: 2,
        },

        Slideshow: {
            playOnStart: slideShow?.playOnStart,
            timeout: slideShow?.timeout,
        }
    });

    Fancybox.bind(`[data-fancybox='allDataGallery-${cId}']`, {
        on: {
            done: () => {

                const videoElements = document.querySelectorAll('.has-html5video video');
                const youtubeVideos = document.querySelectorAll('.has-youtube .fancybox__content');
                const vimeoVideos = document.querySelectorAll('.has-vimeo .fancybox__content');
                Plyr.setup(videoElements, { controls: controlsOpt });
                const youtubePlayers = Plyr.setup(youtubeVideos);
                const vimeoPlayers = Plyr.setup(vimeoVideos);
                youtubePlayers?.forEach(player => player.elements.container.classList.add('fancybox__content'));
                vimeoPlayers?.forEach(player => player.elements.container.classList.add('fancybox__content'));
            },
            init: (fancybox) => {
                setTimeout(() => {
                    if (fancybox.container) {
                        fancybox.container.classList.add("lbb_modal_area-" + cId);
                    }
                }, 100);
            },
        },
        wheel: options.wheel,
        autoFocus: false,
        backdropClick: "close",
        closeButton: "auto",
        commonCaption: false,
        contentClick: "toggleZoom", // "toggleZoom" | "toggleCover" | "toggleMax" | "zoomToFit" | "zoomToMax" | "iterateZoom" | false | "close" | "next" | "prev";
        contentDblClick: "toggleCover", // "toggleZoom" | "toggleCover" | "toggleMax" | "zoomToFit" | "zoomToMax" | "iterateZoom" | false | "close" | "next" | "prev";
        defaultDisplay: "flex",
        Carousel: {
            transition: options?.transition
        },
        Toolbar: {
            items: {
                facebook,
                twitter
            },
            display: {
                left: leftSidePopOP,
                middle: middleSidePopOP,
                right: rightSidePopOP,
            },
        },
        Thumbs: {
            type: thumb.type,
            showOnStart: thumb.showOnStart,
            minCount: 2,
        },

        Slideshow: {
            playOnStart: slideShow?.playOnStart,
            timeout: slideShow?.timeout,

        }
    });

    Fancybox.bind(`[data-fancybox='pdf-gallery-${cId}']`, {

        wheel: options.wheel,
        autoFocus: false,
        backdropClick: "close",
        closeButton: "auto",
        commonCaption: false,
        contentClick: "toggleZoom", // "toggleZoom" | "toggleCover" | "toggleMax" | "zoomToFit" | "zoomToMax" | "iterateZoom" | false | "close" | "next" | "prev";
        contentDblClick: "toggleCover", // "toggleZoom" | "toggleCover" | "toggleMax" | "zoomToFit" | "zoomToMax" | "iterateZoom" | false | "close" | "next" | "prev";
        // defaultDisplay: "flex",
        Carousel: {
            transition: options?.transition
        },
        Toolbar: {
            items: {
                facebook,
                twitter
            },
            display: {
                left: leftSidePopOP,
                middle: middleSidePopOP,
                right: rightSidePopOP,
            },


        },

        Thumbs: {
            type: thumb.type,
            showOnStart: thumb.showOnStart,
            minCount: 2,
        },

        Slideshow: {
            playOnStart: slideShow?.playOnStart,
            timeout: slideShow?.timeout,

        },
        on: {
            init: (fancybox,) => {
                setTimeout(() => {
                    if (fancybox.container) {
                        fancybox.container.classList.add("lbb_modal_area-" + cId);
                        const iframe = document.querySelector(`.lbb_modal_area-${cId} iframe`);
                        loadFrameIfNotLoaded(iframe);
                    }
                }, 100);
            },
        }

    });

    Fancybox.bind(`[data-fancybox='iframe-gallery-${cId}']`, {

        wheel: options.wheel,
        autoFocus: false,
        backdropClick: "close",
        closeButton: "auto",
        commonCaption: false,
        contentClick: "toggleZoom", // "toggleZoom" | "toggleCover" | "toggleMax" | "zoomToFit" | "zoomToMax" | "iterateZoom" | false | "close" | "next" | "prev";
        contentDblClick: "toggleCover", // "toggleZoom" | "toggleCover" | "toggleMax" | "zoomToFit" | "zoomToMax" | "iterateZoom" | false | "close" | "next" | "prev";
        // defaultDisplay: "flex",
        Carousel: {
            transition: options?.transition
        },
        Toolbar: {
            items: {
                facebook,
                twitter
            },
            display: {
                left: leftSidePopOP,
                middle: middleSidePopOP,
                right: rightSidePopOP,
            }
        },
        Thumbs: {
            type: thumb.type,
            showOnStart: thumb.showOnStart,
            minCount: 2,
        },

        Slideshow: {
            playOnStart: slideShow?.playOnStart,
            timeout: slideShow?.timeout,
        },

        on: {
            init: (fancybox,) => {
                setTimeout(() => {
                    if (fancybox.container) {
                        fancybox.container.classList.add("lbb_modal_area-" + cId, "lbb_modal_area");
                    }
                }, 100);
            }
        }
    });
}